/* purgecss start ignore */

@import "tailwindcss/base";
/* purgecss end ignore */
/* @import "./base.css"; */

@import "tailwindcss/components";

@import "tailwindcss/utilities";
@import "./components.css";
/* @import "./utilities.css"; */
